import * as Sentry from '@sentry/vue'
import * as flatted from 'flatted'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const { user } = useAuth()

  const { public: { sentry } } = useRuntimeConfig()
  const tracingOrigins = [
    'localhost',
    /^https:\/\/([a-z0-9-]+\.)*gocanopyroofing\.com$/,
    /^https:\/\/white-rock-01d431e0f-\d+\.eastus2\.azurestaticapps\.net$/
  ]

  if (!sentry.dsn) {
    return
  }

  Sentry.init({
    attachProps: true,
    logErrors: true,
    trackComponents: true,
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment ?? 'development',
    integrations: [
      Sentry.browserTracingIntegration({
        router,
        routeLabel: 'path'
      }), // Add browser tracing integration
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false
      }), // Add replay integration
      Sentry.metrics.metricsAggregatorIntegration(), // Add metrics integration
      Sentry.browserProfilingIntegration(), // Add browser profiling integration
      Sentry.feedbackIntegration({
        // Additional SDK configuration goes in here, for example:
        colorScheme: 'system'
      })
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: tracingOrigins,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    profilesSampleRate: 1.0
  })

  if (user && Sentry) {
    Sentry.setUser(user)
  }

  function safeOverrideConsoleMethod (method) {
    const originalConsoleMethod = console[method]
    if (originalConsoleMethod) {
      console[method] = function (...args) {
        const message = args.map(arg => (typeof arg === 'object' && arg !== null ? flatted.stringify(arg) : arg)).join(' ')
        if (method === 'error') {
          Sentry.captureException(new Error(message))
        } else {
          Sentry.captureMessage(`Client: ${message}`, method)
        }
        originalConsoleMethod.apply(console, args)
      }
    }
  }
  ['log', 'info', 'warn', 'error'].forEach(safeOverrideConsoleMethod)

  // Automatically capture console messages
  // Sentry.inboundFiltersIntegration({
  //   ignoreErrors: [],
  //   ignoreInternal: false,
  //   disableErrorDefaults: false,
  //   disableTransactionDefaults: false,
  //   allowUrls: tracingOrigins
  // })
  // Sentry.breadcrumbsIntegration({
  //   console: true
  // })
  // Sentry.captureConsoleIntegration()

  nuxtApp.vueApp.config.errorHandler = (err, vm, info) => {
    Sentry.captureException(err)
    console.error(err)
  }

  window.onerror = function (message, source, lineno, colno, error) {
    Sentry.captureException(error || new Error(message))
  }

  window.onunhandledrejection = function (event) {
    Sentry.captureException(event.reason)
  }
})
