import { default as indexhKnGp5KL0kMeta } from "/github/workspace/pages/index.vue?macro=true";
import { default as login30e67L1yBNMeta } from "/github/workspace/pages/login.vue?macro=true";
export default [
  {
    name: indexhKnGp5KL0kMeta?.name ?? "index",
    path: indexhKnGp5KL0kMeta?.path ?? "/",
    meta: indexhKnGp5KL0kMeta || {},
    alias: indexhKnGp5KL0kMeta?.alias || [],
    redirect: indexhKnGp5KL0kMeta?.redirect,
    component: () => import("/github/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login30e67L1yBNMeta?.name ?? "login",
    path: login30e67L1yBNMeta?.path ?? "/login",
    meta: login30e67L1yBNMeta || {},
    alias: login30e67L1yBNMeta?.alias || [],
    redirect: login30e67L1yBNMeta?.redirect,
    component: () => import("/github/workspace/pages/login.vue").then(m => m.default || m)
  }
]