import validate from "/github/workspace/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/github/workspace/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/github/workspace/middleware/auth.ts"),
  public: () => import("/github/workspace/middleware/public.ts"),
  "auth-logged-in": () => import("/github/workspace/node_modules/@nuxtjs/kinde/dist/runtime/middleware/auth-logged-in.mjs"),
  "auth-logged-out": () => import("/github/workspace/node_modules/@nuxtjs/kinde/dist/runtime/middleware/auth-logged-out.mjs")
}